import { inject } from '@angular/core';
import {
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
  ResolveFn
} from '@angular/router';
import { of, Subscription } from 'rxjs';
import { AppStateService } from 'src/app/shared/services/app-state/app-state.service';
import { TvDevice } from '@resparke/models';
import { DeviceService } from 'src/app/shared/services/device/device.service';

export const tvsResolver: ResolveFn<any> =
  // (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const loadAsync = Array.isArray(route.data.resolveAsync) && route.data.resolveAsync.includes('tvs');

    const appState = inject(AppStateService);
    const deviceService = inject(DeviceService);
    let facilityId;

    if (route.params.facilityId) {
      facilityId = route.params.facilityId as string;
      appState.set('currentFacilityId', facilityId);
    } else {
      facilityId = appState.get<string>('currentFacilityId');
    }

    if (facilityId) {
      const tvsCollection = appState.get<TvDevice[]>('tvsCollection') || [];

      if (Array.isArray(tvsCollection) && tvsCollection.length > 0) {
        return of(tvsCollection);
      } else {
        if (loadAsync) {
          // load Non Blocking
          const sub: Subscription = deviceService.getTvs().subscribe(() => sub.unsubscribe());
        } else {
          // load Blocking
          return deviceService.getTvs();
        }
      }
    }
    return undefined;
  };
